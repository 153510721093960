export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const orderDeliveryButton = ''
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874348/samuraisushi/about.png'
export const aboutFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874348/samuraisushi/aboutFraser.png'
export const contactbg =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610478503/samuraisushi/contactbg.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874357/samuraisushi/foodGallery.png'
export const foodGalleryFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874357/samuraisushi/foodGalleryFraser.png'
export const hero =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610478504/samuraisushi/hero.jpg'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874362/samuraisushi/homebg.jpg'
export const locationMapDavie =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610478506/samuraisushi/locationMapDavie.jpg'
export const locationMapFraser =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610478506/samuraisushi/locationMapFraser.jpg'
export const logo =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610478506/samuraisushi/logo.png'
export const logoWhite =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610478506/samuraisushi/logoWhite.png'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695093829/samuraisushi/mobileAboutDavie.webp'
export const mobileAboutFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874379/samuraisushi/mobileAboutFraser.png'
export const mobileAbout2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874378/samuraisushi/mobileAbout2.png'
export const mobileAbout2Fraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874379/samuraisushi/mobileAbout2Fraser.png'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874379/samuraisushi/mobileContactbg.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874385/samuraisushi/mobileGallery.png'
export const mobileGalleryFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874385/samuraisushi/mobileGalleryFraser.png'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874385/samuraisushi/mobileHero.jpg'
export const mobileMapDavie =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874385/samuraisushi/mobileMapDavie.jpg'
export const mobileMapFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874385/samuraisushi/mobileMapFraser.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874389/samuraisushi/mobilePromotion.png'
export const mobilePromotionFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874390/samuraisushi/mobilePromotionFraer.png'
export const orderPickupButton =
  'https://res.cloudinary.com/cforder/image/upload/f_auto,q_auto:best/v1610478510/samuraisushi/orderPickupButton.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1695093829/samuraisushi/promotionsDavie.webp'
export const promotionsFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874394/samuraisushi/promotionsFraser.png'
export const viewMenuButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1608578558/samuraisushi/viewMenuButton.png'

export { default as FraserMenu } from './SamuraiFraserMenu.pdf'
export { default as DavieMenu } from './samuraiDavie2.pdf'
