import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  mobilePromotion,
  mobilePromotionFraser,
  promotionsFraser,
  promotions,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let promotionMobileImage = null
        let promotionsImage = null
        if (locationId === 'UeW1ehQXkWV2jXhJ14lx') {
          promotionsImage = promotionsFraser
          promotionMobileImage = mobilePromotionFraser
        } else {
          promotionsImage = promotions
          promotionMobileImage = mobilePromotion
        }
        return (
          <CFView>
            <MobileScreen>
              <CFImage
                src={promotionMobileImage}
                w="100%"
                alt="Samurai Sushi Map"
              />
            </MobileScreen>
            <DefaultScreen>
              <CFImage src={promotionsImage} w="100%" alt="Samurai Sushi Map" />
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
