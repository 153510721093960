import React, { useState } from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
  PrimaryModal,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import ViewMenuButton from './ViewMenuButton'
import {
  hero,
  heroText,
  headerbg,
  mobileHero,
  mobileHeroText,
  viewMenuButton,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="90VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          center
          justifyBetween
        >
          <Header />
          <CFView
            column
            center
            ph="3%"
            w="100%"
            maxWidth="1400px"
            pt="5px"
            bg="rgba(200, 190, 120, 0.8)"
          >
            {/* <CFView textCenter black raleway bold h1>
              NOW TAKING ONLINE ORDERS
            </CFView> */}
            <CFView mv="5px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView hover mb="5px">
              <ViewMenuButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          justifyBetween
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          zIndex={90}
          relative
        >
          <Header />
          <CFView
            row
            center
            ph="3%"
            w="100%"
            maxWidth="1400px"
            pv="5px"
            bg="rgba(200, 190, 120, 0.8)"
          >
            {/* <CFView mb="1.5px" black raleway bold style={{ fontSize: 32 }}>
              NOW TAKING ONLINE ORDERS
            </CFView> */}
            <CFView pt="3px" ml="25px">
              <OrderPickupButton />
            </CFView>
            <CFView hover pt="3px" ml="15px">
              <ViewMenuButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
