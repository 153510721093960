import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  foodGallery,
  foodGalleryFraser,
  mobileGallery,
  mobileGalleryFraser,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let GalleryMobileImage = null
        let GalleryImage = null
        if (locationId === 'UeW1ehQXkWV2jXhJ14lx') {
          GalleryImage = foodGalleryFraser
          GalleryMobileImage = mobileGalleryFraser
        } else {
          GalleryImage = foodGallery
          GalleryMobileImage = mobileGallery
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage src={GalleryMobileImage} w="100%" alt="Food Gallery" />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView column center maxWidth="1200px" m="30px auto">
                <CFImage src={GalleryImage} w="100%" alt="Food Gallery" />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
