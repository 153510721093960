import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  about,
  aboutFraser,
  mobileAbout,
  mobileAboutFraser,
  mobileAbout2,
  mobileAbout2Fraser,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let AboutMobileImage = null
        let AboutMobileImage2 = null
        let AboutImage = null
        if (locationId === 'UeW1ehQXkWV2jXhJ14lx') {
          AboutImage = aboutFraser
          AboutMobileImage = mobileAboutFraser
          AboutMobileImage2 = mobileAbout2Fraser
        } else {
          AboutImage = about
          AboutMobileImage = mobileAbout
          AboutMobileImage2 = mobileAbout2
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage
                  src={AboutMobileImage}
                  w="100%"
                  alt="aboutImageMobile"
                />
                <CFImage
                  src={AboutMobileImage2}
                  w="100%"
                  alt="aboutImageMobile"
                />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView row justifyStart w="100%" maxWidth="1400px">
                <CFImage src={AboutImage} w="100%" alt="about Image" />
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
